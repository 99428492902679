import { Grid } from 'components';
import { AmountInput } from '../../AmountInput';
import { CounterpartyAutocomplete } from '../AgentAutocomplete';
import { Select } from 'components/form';
import { MenuItem } from '@material-ui/core';
import { AgentFeeRecipient } from 'schema';
import { AgentFeeWithNdsSwitch } from './AgentFeeWithNdsSwitch';
import { useTranslation } from 'react-i18next';
import { Control, UseFormSetValue, useWatch } from 'react-hook-form';
import { CalculatorFormValues } from '../../types';
import { useCounterpartyQuery } from 'services';
import { useEffect } from 'react';

type AgentFieldsProps = {
  control: Control<CalculatorFormValues, any>;
  setValue: UseFormSetValue<CalculatorFormValues>;
};

export const AgentFields = (props: AgentFieldsProps) => {
  const { t } = useTranslation();
  const { control, setValue } = props;

  const agentInn =
    useWatch({
      control,
      name: 'agent',
    }) ?? '';

  const agentObject = useCounterpartyQuery(agentInn);

  const contracts = agentObject.data?.contracts ?? [];

  useEffect(() => {
    setValue(
      'agentFeeRecipient',
      agentObject.data?.isPhysic
        ? AgentFeeRecipient.Individual
        : agentObject.data?.isIndividualEntrepreneur
        ? AgentFeeRecipient.IndividualEntrepreneur
        : AgentFeeRecipient.Entity
    );
    setValue('isAgentFeeWithNds', agentObject.data?.isVatPayer);
  }, [
    setValue,
    agentObject.data?.isIndividualEntrepreneur,
    agentObject.data?.isPhysic,
    agentObject.data?.isVatPayer,
  ]);

  return (
    <Grid container columnSpacing={2} rowSpacing={2.5}>
      <Grid item xl={4} md={4} xs={24}>
        <AmountInput name="agentFee" label={t('AgentFee')} fractionDigits={8} />
      </Grid>
      <Grid item xl={4} md={9} xs={24}>
        <CounterpartyAutocomplete name="agent" type="isAgent" />
      </Grid>
      <Grid item xl={4} md={6} xs={24}>
        <Select
          name="agentFeeRecipient"
          label={t('AgentFeeRecipient')}
          control={control}
          disabled={agentInn !== ''}
        >
          <MenuItem value={AgentFeeRecipient.Entity}>{t('AgentFeeRecipientType.Entity')}</MenuItem>
          <MenuItem value={AgentFeeRecipient.Individual}>
            {t('AgentFeeRecipientType.Individual')}
          </MenuItem>
          <MenuItem value={AgentFeeRecipient.IndividualEntrepreneur}>
            {t('AgentFeeRecipientType.IndividualEntrepreneur')}
          </MenuItem>
        </Select>
      </Grid>
      <Grid item xl={4} md={5} xs={24}>
        <AgentFeeWithNdsSwitch control={control} setValue={setValue} disabled={agentInn !== ''} />
      </Grid>
      <Grid item xl={8} md={24} xs={24}>
        <Select
          displayEmpty
          name="agentContractId"
          label={t('Contract')}
          control={control}
          defaultValue={
            contracts.find((contract) => contract.isMain && contract.isFramework)?.externalId || ''
          }
        >
          {contracts.map((contract) => (
            <MenuItem value={contract.externalId} key={contract.externalId}>
              {contract.name}
            </MenuItem>
          ))}
        </Select>
      </Grid>
    </Grid>
  );
};
