import { makeStyles, createStyles, Typography, Theme, Divider, MenuItem } from '@material-ui/core';
import { Grid } from 'components/Grid';
import { useTranslation } from 'react-i18next';
import { AmountInput, FixedAmountInput } from '../AmountInput';
import { useCalculationFormContext } from '../types';
import { Checkbox, Radio, Select, Switch } from 'components';
import { useWatch } from 'react-hook-form';
import {
  AmountType,
  AuditRegularity,
  AuditType,
  CalculationMethodType,
  InsuranceFranchise,
  Telematics,
} from 'schema/serverTypes';
import { CounterpartyAutocomplete } from '../CounterpartyAutocomplete';
import { SubsidyFields } from './SubsidyFields';
import { TelematicsSelect } from './TelematicsSelect';
import { KrimistopTelematicsCountInput } from './KrimistopTelematicsCountInput';
import { useEffect } from 'react';
import { AgentFields } from './AgentFields';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      width: '100%',
    },
    divider: {
      marginBottom: theme.spacing(2.5),
    },
    icon: {
      borderLeft: '1px solid' + theme.palette.secondary.main,
      width: theme.spacing(4),
      height: theme.spacing(4),
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    checkTitle: {
      fontFamily: 'Roboto, Helvetica, Arial, san-serif',
      height: '100%',
      display: 'flex',
      alignItems: 'center',
    },
    alignCenter: {
      display: 'flex',
      alignItems: 'center',
    },
    audit: {
      minHeight: 47,
    },
  })
);

export const ComponentsFields = () => {
  const classes = useStyles();

  const { t } = useTranslation();
  const { control, getValues, setValue, reset } = useCalculationFormContext();

  const hasInsurance =
    useWatch({
      control,
      name: 'hasInsurance',
    }) ?? false;

  const telematics = useWatch({
    control,
    name: 'telematics',
  });

  const hasAudit = useWatch({
    control,
    name: 'hasAudit',
  });

  const calculationMethodType = useWatch({
    control,
    name: 'calculationMethodType',
  });

  const irrDisabled = calculationMethodType !== CalculationMethodType.ReverseIRR;
  const rizePriceDisabled = calculationMethodType !== CalculationMethodType.Reverse;

  useEffect(() => {
    if (!hasInsurance) {
      setValue('insuranceRatePercents', undefined);
      setValue('insuranceCompany', undefined);
      setValue('insuranceCompanyName', undefined);
      setValue('franchiseAmount', undefined);
      setValue('insuranceFranchise', undefined);
      reset({ ...getValues() });
    }
  }, [hasInsurance, getValues, setValue, reset]);

  return (
    <div className={classes.wrapper}>
      <Grid container columnSpacing={2} rowSpacing={2.5}>
        <Grid item md="auto" xs={24}>
          <Switch control={control} name="hasInsurance" label={t('Insurance')} type="checkbox" />
        </Grid>
        {hasInsurance && (
          <>
            <Grid item xl={6} lg={9} md={7} xs={24}>
              <CounterpartyAutocomplete type="insuranceCompany" />
            </Grid>
            <Grid item xl={3} md={5} xs={24}>
              <FixedAmountInput
                name="insuranceRatePercents"
                label={`${t('InsuranceRate')}, %`}
                amountMode={AmountType.Percents}
              />
            </Grid>
            <Grid item xl={3} lg={4} md={7} xs={24}>
              <Select control={control} name="insuranceFranchise" label={t('Franchise')}>
                <MenuItem value={InsuranceFranchise.None}>{t('InsuranceFranchise.None')}</MenuItem>
                <MenuItem value={InsuranceFranchise.Unconditional}>
                  {t('InsuranceFranchise.Unconditional')}
                </MenuItem>
                <MenuItem value={InsuranceFranchise.Conditional}>
                  {t('InsuranceFranchise.Conditional')}
                </MenuItem>
              </Select>
            </Grid>
            <Grid item xl={4} lg={5} md={4} xs={24}>
              <FixedAmountInput
                name="franchiseAmount"
                label={t('Amount')}
                amountMode={AmountType.Money}
                //icon="$"
              />
            </Grid>
          </>
        )}
      </Grid>

      <Divider light className={classes.divider} />

      <Grid container columnSpacing={2} rowSpacing={2.5}>
        <Grid item xl={3} lg={5} md={6} xs={24}>
          <AmountInput name="itemDiscount" label={t('Discount')} useSaleCurrency={true} />
        </Grid>
        <Grid item xl={4} lg={6} md={8} xs={24}>
          <TelematicsSelect />
        </Grid>
        {(telematics === Telematics.XproKrimistop || telematics === Telematics.Krimistop) && (
          <Grid item xl={4} lg={6} md={8} xs={24}>
            <KrimistopTelematicsCountInput />
          </Grid>
        )}
      </Grid>

      <Divider light className={classes.divider} />
      <SubsidyFields control={control} setValue={setValue} />
      <Divider light className={classes.divider} />
      <AgentFields control={control} setValue={setValue} />
      <Divider light className={classes.divider} />

      <Grid container columnSpacing={2} rowSpacing={2.5} className={classes.audit}>
        <Grid item md="auto" xs={24} className={classes.alignCenter}>
          <Switch name="hasAudit" label={t('Audit')} type="checkbox" control={control} />
        </Grid>
        {hasAudit === true && (
          <>
            <Grid item md="auto" xs={24} className={classes.alignCenter}>
              <Radio
                name="auditType"
                label={t('Type')}
                control={control}
                options={[
                  { label: 'Desk', value: AuditType.Desk },
                  { label: 'Field', value: AuditType.Field },
                  { label: 'Mobile', value: AuditType.Mobile },
                ]}
              />
            </Grid>
            <Grid item xl={3} lg={5} md={6} xs={24} className={classes.alignCenter}>
              <Select name="auditRegularity" label={t('AuditRegularity')} control={control}>
                <MenuItem value={AuditRegularity.Month1}>
                  {t('MonthWithCount', { count: 1 })}
                </MenuItem>
                <MenuItem value={AuditRegularity.Month3}>
                  {t('MonthWithCount', { count: 3 })}
                </MenuItem>
                <MenuItem value={AuditRegularity.Month6}>
                  {t('MonthWithCount', { count: 6 })}
                </MenuItem>
                <MenuItem value={AuditRegularity.Month12}>
                  {t('MonthWithCount', { count: 12 })}
                </MenuItem>
                <MenuItem value={AuditRegularity.Month24}>
                  {t('MonthWithCount', { count: 24 })}
                </MenuItem>
              </Select>
            </Grid>
          </>
        )}
        <Grid item md="auto" xs={24} className={classes.alignCenter}>
          <Switch name="isSubleasing" label={t('Subleasing')} type="checkbox" control={control} />
        </Grid>
      </Grid>

      <Divider light className={classes.divider} />

      <Grid container columnSpacing={2} rowSpacing={2.5}>
        <Grid item xs="auto">
          <Typography component="span" noWrap={true} className={classes.checkTitle}>
            {t('ConsiderIinPercentsRiseInPrice')}
          </Typography>
        </Grid>
        <Grid item xs={23}>
          <Checkbox name="hasFeePriceRule" label={t('Commission')} control={control} />
          <Checkbox name="hasInsurancePriceRule" label={t('Insurance')} control={control} />
          <Checkbox name="hasTelematicsPriceRule" label={t('Telematics')} control={control} />
          <Checkbox name="hasSubsidyPriceRule" label={t('Discount')} control={control} />
        </Grid>
      </Grid>

      <Divider light className={classes.divider} />

      <Grid container columnSpacing={2} rowSpacing={2.5}>
        <Grid item xl={5} lg={6} md={8} xs={24}>
          <Select name="calculationMethodType" label={t('CalculationMethod')} control={control}>
            <MenuItem value={CalculationMethodType.Forward}>
              {t('CalculationMethodType.Forward')}
            </MenuItem>
            <MenuItem value={CalculationMethodType.Reverse}>
              {t('CalculationMethodType.Reverse')}
            </MenuItem>
            <MenuItem value={CalculationMethodType.ReverseIRR}>
              {t('CalculationMethodType.ReverseIRR')}
            </MenuItem>
          </Select>
        </Grid>
        <>
          {!irrDisabled && (
            <Grid item xl={3} lg={4} md={5} xs={24}>
              <FixedAmountInput
                name="irrPercents"
                label={t('IRR')}
                disabled={irrDisabled}
                amountMode={AmountType.Percents}
                icon="%"
              />
            </Grid>
          )}
          {!rizePriceDisabled && (
            <Grid item xl={3} lg={4} md={5} xs={24}>
              <FixedAmountInput
                name="rizeInPricePercents"
                label={t('RizeInPrice')}
                disabled={rizePriceDisabled}
                amountMode={AmountType.Percents}
                icon="%"
              />
            </Grid>
          )}
        </>
      </Grid>
    </div>
  );
};
