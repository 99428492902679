import { useMemo, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  CalculationResult,
  QuotaCalculationResult,
  QuotaCalculationInput,
  ValidationProblemDetails,
  AmountType,
  ScoringModelRuleResultViewModel,
  ScoringModelContextFieldValidationError,
} from 'schema/serverTypes';
import { CalculatorFormValues } from '../CalculationForm/types';
import { useQuotasBackendMutation } from 'services';
import { getQuotaCalculationInput } from '../CalculationForm/getQuotaCalculationInput';
import { useQueryClient } from 'react-query';
import { useToast } from '../../Toast/useToast';
import { useTranslation } from 'react-i18next';
import {
  calculateFundingAmount,
  calculateGeneralRatePercents,
  calculatePrepaymentAmount,
  calculateTradeFeeAmount,
} from '../CalculationForm/calculations';
import { useForm } from 'react-hook-form';
import { useModalForm } from '../../Modal';

export const useQuotaCalculatorForm = (quota: QuotaCalculationResult) => {
  const id = quota.quotaId;
  const { t } = useTranslation();
  const toast = useToast();

  const [calculationResult, setCalculationResult] = useState<CalculationResult>(
    quota.calculationResult
  );

  const [error, setError] = useState<ValidationProblemDetails | null>(null);
  const resultRef = useRef<HTMLDivElement | null>(null);

  const { mutateAsync: calculateAsync, isLoading: isCalculating } = useQuotasBackendMutation<
    CalculatorFormValues,
    CalculationResult
  >('calculation', {
    onError: (error) => {
      toast(t('CalculationErrorMessage'), 'error');
      setError(error);
    },
    onSuccess: (result, values) => {
      if (result.isInvalidPrizeRizing) {
        toast(t('InvalidPrizeRisingMessage'), 'error');
      } else {
        toast(t('CalculationSuccessMessage'), 'success');
      }

      setCalculationResult(result);
      setValue('cofPercents', result.cofPercents);
      resultRef.current?.scrollIntoView({ behavior: 'smooth' });
      //refetch lessee for new rating DLLCRMV2-1346
      queryClient.invalidateQueries({
        predicate: (query) => {
          return (
            typeof query.queryKey === 'string' &&
            (query.queryKey as string).indexOf('type=lessee') >= 0
          );
        },
      });
    },
  });

  const {
    onOpen: onOpenApprovalError,
    onClose: onCloseApprovalError,
    open: openApprovalError,
  } = useModalForm();

  const [invalidScoringRules, setInvalidScoringRules] = useState<
    ScoringModelRuleResultViewModel[] | undefined
  >();
  const [corridorErrors, setCorridorErrors] = useState<
    ScoringModelContextFieldValidationError[] | undefined
  >();

  const queryClient = useQueryClient();

  const { mutateAsync: updateAsync, isLoading: isUpdating } = useQuotasBackendMutation<
    QuotaCalculationInput,
    QuotaCalculationResult
  >(`${id}`, {
    method: 'PUT',
    onError: (error) => {
      toast(t('SaveErrorMessage'), 'error');
      setError(error);
    },
    onSuccess: (quota) => {
      if (quota.calculationResult.isInvalidPrizeRizing) {
        toast(t('InvalidPrizeRisingMessage'), 'error');
      } else if (quota.invalidScoringRules !== undefined && quota.invalidScoringRules.length > 0) {
        onOpenApprovalError();
        setInvalidScoringRules(quota.invalidScoringRules);
      } else if (quota.corridorErrors !== undefined && quota.corridorErrors.length > 0) {
        onOpenApprovalError();
        setCorridorErrors(quota.corridorErrors);
      } else {
        toast(t('SaveSuccessMessage'), 'success');
      }
      setCalculationResult(quota.calculationResult);
      setValue('cofPercents', quota.input.cofPercents);

      queryClient.invalidateQueries({
        predicate: (query) => {
          return Array.isArray(query.queryKey) && query.queryKey.indexOf('quotas') >= 0;
        },
      });
      //refetch lessee for new rating DLLCRMV2-1346
      queryClient.invalidateQueries({
        predicate: (query) => {
          return (
            typeof query.queryKey === 'string' &&
            (query.queryKey as string).indexOf('type=lessee') >= 0
          );
        },
      });
    },
  });

  const { mutateAsync: copyAsync, isLoading: isCopying } = useQuotasBackendMutation<
    QuotaCalculationInput,
    QuotaCalculationResult
  >(`${id}/copy`, {
    onSuccess: () => {
      setValue('copy', false);
    },
    onError: (error) => {
      setError(error);
    },
  });

  const {
    input,
    agreement,
    calculationResult: result,
    dealer,
    lessee,
    vendor,
    insuranceCompany,
    dealerName,
    lesseeName,
    insuranceCompanyName,
    industryLeasingProductId,
  } = quota;
  const { leaseItemCost } = result;
  const {
    cofPercents = 0,
    marginPercents = 0,
    itemDiscount = {
      value: 0,
      type: AmountType.Money,
    },
  } = input;

  const defaultValues: CalculatorFormValues = useMemo(() => {
    const { prepayment = { type: AmountType.Percents, value: 0 }, tradeFee = 0 } = input;
    const prepaymentAmount = calculatePrepaymentAmount(leaseItemCost, prepayment, itemDiscount);
    const fundingAmount = calculateFundingAmount(leaseItemCost, prepaymentAmount, itemDiscount);
    const tradeFeeAmount = calculateTradeFeeAmount(leaseItemCost, tradeFee, itemDiscount);
    const generalRatePercents = calculateGeneralRatePercents(cofPercents, marginPercents);

    return {
      ...input,
      ...agreement,
      vatEnabled: (input.vatPercents ?? 0) > 0,
      dealerName,
      lesseeInn: lessee,
      lesseeName,
      insuranceCompanyName,
      leaseItemCost,
      prepaymentAmount,
      tradeFeeAmount,
      fundingAmount,
      dealer,
      lessee,
      vendor,
      insuranceCompany,
      generalRatePercents,
      industryLeasingProductId,
    };
  }, [
    input,
    agreement,
    leaseItemCost,
    dealer,
    lessee,
    vendor,
    insuranceCompany,
    cofPercents,
    marginPercents,
    itemDiscount,
    dealerName,
    lesseeName,
    insuranceCompanyName,
    industryLeasingProductId,
  ]);

  const isLoading = isUpdating || isCalculating || isCopying;

  const form = useForm<CalculatorFormValues>({
    mode: 'onBlur',
    defaultValues,
  });

  const { handleSubmit, setValue } = form;

  const { push, go } = useHistory();

  const onSubmit = useMemo(() => {
    const submit = async (values: CalculatorFormValues) => {
      const request = getQuotaCalculationInput(values);
      if (values.copy) {
        const result = await copyAsync(request);
        if (result.quotaId) {
          push(`/quotas/calculator/${result.quotaId}`);
          go(0);
        }
      } else if (values.save) {
        const result = await updateAsync(request);
        setValue('leaseItemCost', result.calculationResult.leaseItemCost);
      } else {
        const calcResult = await calculateAsync(request);
        setValue('leaseItemCost', calcResult.leaseItemCost);
      }
    };
    return handleSubmit(submit);
  }, [updateAsync, calculateAsync, copyAsync, push, handleSubmit, setValue, go]);

  return {
    isLoading,
    onSubmit,
    defaultValues,
    calculationResult,
    error,
    resultRef,
    form,
    approvalErrorPopup: {
      onCloseApprovalError,
      openApprovalError,
      corridorErrors,
      invalidScoringRules,
    },
  };
};
