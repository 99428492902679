import { Grid } from '@material-ui/core';
import { Paging } from '../paging';
import { PageTitle } from 'components/utils/pageTitle';
import { useTranslation } from 'react-i18next';
import { useFutureShipmentListData } from './useFutureShipmentListData';
import { FutureShipmentFilterForm } from './FutureShipmentFilterForm';
import { FutureShipmentTable } from './FutureShipmentTable';

type FutureShipmentsDataGridProps = {
  tabId?: number;
};

export const FutureShipmentDataGrid = (props: FutureShipmentsDataGridProps) => {
  const { t } = useTranslation();
  const pageTitle = t('Future shipments') + ' - Bumblebee';
  PageTitle(pageTitle);

  const { tabId = 0 } = props;

  const { rows, isLoading, paging, filter, sorting } = useFutureShipmentListData({
    ...props,
    tabId,
  });

  const { control, onReset, setValue } = filter;

  return (
    <>
      <FutureShipmentFilterForm control={control} onReset={onReset} setValue={setValue} />
      <Grid container item direction="column">
        <Grid item>
          <FutureShipmentTable rows={rows} isLoading={isLoading} sorting={sorting} />
        </Grid>
        <Grid item>
          <Paging {...paging} />
        </Grid>
      </Grid>
    </>
  );
};
