import { useTranslation } from 'react-i18next';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Grid } from '../Grid';
import { MenuItem, Typography } from '@material-ui/core';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Accordion from '@material-ui/core/Accordion';
import { Input, Select, Switch } from '../form';
import { useForm } from 'react-hook-form';
import { Button } from '../Button';
import { CreateFormFieldValues } from './types';
import { TemplateKind, TemplateViewModel } from 'schema/serverTypes';
import React, { useMemo } from 'react';
import { useTemplatesFileUploadMutation } from 'services/api';
import { useHistory } from 'react-router';
import { FileUpload } from './FileUpload';
import { UserSelectField } from './UserSelectField';
import { LessorSelectField } from './LessorSelectField';
import { DealerSelectField } from './DealerSelectField';
import { getFormData } from './getFormData';
import { useTemplateKind } from './useTemplateKind';
import { TagList } from './TagList';
import { IconBackTo } from '../icons';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    head: {
      color: theme.palette.secondary.main,
    },
    wrapper: {
      width: '100%',
      display: 'grid',
      gridTemplateColumns: '45fr 50fr',
      gridColumnGap: theme.spacing(2.5),
    },
    span: {
      marginLeft: theme.spacing(1.6),
    },
    form: {},
  })
);

export const TemplateCreateForm = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { control, handleSubmit, setValue } = useForm<CreateFormFieldValues>({
    mode: 'onBlur',
    defaultValues: {
      title: '',
      description: '',
      kind: TemplateKind.Calculation,
      file: null,
      fileName: '',
      users: [],
      dealers: [],
      lessor: null,
      isAvailabelForAllUsers: false,
      isAvailableSendingToClient: true,
    },
  });

  const { mutateAsync } = useTemplatesFileUploadMutation<CreateFormFieldValues, TemplateViewModel>(
    '',
    getFormData
  );

  const { push } = useHistory();

  const onSubmit = useMemo(() => {
    const submit = async (values: CreateFormFieldValues) => {
      const template = await mutateAsync(values);
      if (template.id) {
        push(`/templates`, template);
      }
    };
    return handleSubmit(submit);
  }, [handleSubmit, mutateAsync, push]);

  const { isContractKind } = useTemplateKind(control);

  return (
    <div className={classes.wrapper}>
      <div>
        <div>
          <Button variant="iconButton" endIcon={<IconBackTo />} to="/templates" />
          <span className={classes.span}>{t('New template')}</span>
        </div>
        <Accordion defaultExpanded={true}>
          <AccordionSummary aria-controls="panel1a-content" id="panel1a-header">
            <Typography variant="subtitle1">{t('Create template')}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <form className={classes.root}>
              <Grid container columnSpacing={2} rowSpacing={2.5}>
                <Grid item xs={24}>
                  <Select label={`${t('Template type')}`} name="kind" control={control}>
                    <MenuItem value={TemplateKind.Calculation}>{t('Calculation')}</MenuItem>
                    <MenuItem value={TemplateKind.CalculationDetailed}>
                      {t('Calculation detailed')}
                    </MenuItem>
                    <MenuItem value={TemplateKind.External1C}>{t('Exchange with 1C')}</MenuItem>
                    <MenuItem value={TemplateKind.Contract}>{t('Contract')}</MenuItem>
                    <MenuItem value={TemplateKind.Shipment}>{t('Shipment')}</MenuItem>
                    <MenuItem value={TemplateKind.Pipeline}>{t('Pipeline')}</MenuItem>
                    <MenuItem value={TemplateKind.Quotas}>{t('Quotas')}</MenuItem>
                  </Select>
                </Grid>
                <Grid item xs={24}>
                  <FileUpload
                    name={'fileName'}
                    control={control}
                    setValue={setValue}
                    required={true}
                  />
                </Grid>
                <Grid item xs={24}>
                  <Input
                    label={`${t('Template name')}`}
                    name="title"
                    control={control}
                    rules={{
                      required: {
                        value: true,
                        message: t('Required'),
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={24}>
                  <Input
                    multiline={true}
                    label={`${t('Description')}`}
                    name="description"
                    control={control}
                  />
                </Grid>
                {!isContractKind && (
                  <Grid item xs={24}>
                    <UserSelectField
                      control={control}
                      required={!isContractKind}
                      setValue={setValue}
                    />
                  </Grid>
                )}
                {isContractKind && (
                  <>
                    <Grid item md={12} xs={24}>
                      <LessorSelectField control={control} required={isContractKind} />
                    </Grid>
                    <Grid item md={12} xs={24}>
                      <DealerSelectField control={control} required={isContractKind} />
                    </Grid>
                  </>
                )}
                <Grid item xs={'auto'}>
                  <Switch
                    label={'Доступен для отправки клиенту'}
                    name="isAvailableSendingToClient"
                    control={control}
                    labelOn={''}
                    labelOff={''}
                    titleWrap={false}
                  />
                </Grid>
                <Grid item xs={24}>
                  <Button variant="contained" onClick={onSubmit}>
                    {t('Create')}
                  </Button>
                </Grid>
              </Grid>
            </form>
          </AccordionDetails>
        </Accordion>
      </div>
      <TagList />
    </div>
  );
};
